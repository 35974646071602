// Helper function to import translation chunks
function importTranslationChunks(baseName: string, totalChunks: number) {
    const chunks: { [key: string]: any } = {};
    for (let i = 1; i <= totalChunks; i++) {
        chunks[`${baseName}_${i}`] = require(`@/translations/${baseName}_${i}.json`);
    }
    return Object.values(chunks).reduce((acc, chunk) => ({ ...acc, ...chunk }), {});
}

// Import and combine translation chunks
const webapp = importTranslationChunks('webapp', 22);
const encryption = importTranslationChunks('encryption', 1);
const manifesto = importTranslationChunks('manifesto', 1);
const changelog = importTranslationChunks('changelog', 1);
const security = importTranslationChunks('security', 1);

export const SupportedLocales = ['en', 'uk', 'ru', 'es', 'ka', 'de', 'fr'] as const;
export const DEFAULT_LOCALE = 'en';
export type SupportedLocale = typeof SupportedLocales[number];
export type LocalizedString = string | { [key in SupportedLocale]: string; };

export interface Language {
    code: SupportedLocale;
    name: string;
    flag: string;
}

export const supportedLanguages: Language[] = [
    { code: 'en', name: 'English', flag: '🇬🇧' },
    { code: 'es', name: 'Español', flag: '🇪🇸' },
    { code: 'de', name: 'Deutsch', flag: '🇩🇪' },
    { code: 'fr', name: 'Français', flag: '🇫🇷' },
    { code: 'uk', name: 'Українська', flag: '🇺🇦' },
    { code: 'ka', name: 'ქართული', flag: '🇬🇪' },
    { code: 'ru', name: 'Русский', flag: '◻️' },
];

type TranslationEntry = {
    [key in Exclude<SupportedLocale, typeof DEFAULT_LOCALE>]: string;
};

export type TranslationsType = {
    [key: string]: TranslationEntry;
};

export const translationSources: { [key: string]: TranslationsType } = {
    webapp,
    encryption,
    manifesto,
    changelog,
    security,
};

export interface TranslationOptions {
    suppressErrors?: boolean;
    source?: string | TranslationsType;
    placeholders?: { [key: string]: string | number };
}

class TranslationManager {
    private static instance: TranslationManager;
    private currentLocale: SupportedLocale = DEFAULT_LOCALE;
    private translationCache: Map<string, string> = new Map();

    private constructor() { }

    public static getInstance(): TranslationManager {
        if (!TranslationManager.instance) {
            TranslationManager.instance = new TranslationManager();
        }
        return TranslationManager.instance;
    }

    public getLocale(): SupportedLocale {
        return this.currentLocale;
    }

    public setLocale(locale: SupportedLocale) {
        this.currentLocale = locale;
        this.translationCache.clear();
    }

    private replacePlaceholders(text: string, placeholders?: { [key: string]: string | number }): string {
        if (!placeholders) return text;
        return text.replace(/\{\{(\w+)\}\}/g, (_, key) => String(placeholders[key] ?? `{{${key}}}`));
    }

    private normalizeKey(key: string): string {
        // Normalize whitespace and case
        return key.trim().toLowerCase();
    }

    private findTranslationInSources(normalizedKey: string, currentLocale: SupportedLocale): string | null {
        for (const [sourceKey, sourceObj] of Object.entries(translationSources)) {
            // Search through all keys in the source
            for (const [key, translations] of Object.entries(sourceObj)) {
                if (this.normalizeKey(key) === normalizedKey) {
                    const translation = translations[currentLocale as Exclude<SupportedLocale, typeof DEFAULT_LOCALE>];
                    if (translation) {
                        return translation;
                    }
                }
            }
        }
        return null;
    }

    public __(defaultLanguageStringOrLocalizedString: string | LocalizedString, options: TranslationOptions = {}): string {
        const { suppressErrors = false, source = '', placeholders } = options;
        const currentLocale = this.getLocale();

        const cacheKey = `${currentLocale}:${String(defaultLanguageStringOrLocalizedString)}:${source}`;
        const cachedTranslation = this.translationCache.get(cacheKey);
        if (cachedTranslation) {
            return this.replacePlaceholders(cachedTranslation, placeholders);
        }

        if (typeof defaultLanguageStringOrLocalizedString === 'string') {
            let translatedText = '';

            // Only search for translations if the current locale is not the default locale
            if (currentLocale !== DEFAULT_LOCALE) {
                const normalizedKey = this.normalizeKey(defaultLanguageStringOrLocalizedString);
                const translation = this.findTranslationInSources(normalizedKey, currentLocale);
                if (translation) {
                    translatedText = translation;
                }
            }

            if (translatedText) {
                this.translationCache.set(cacheKey, translatedText);
                return this.replacePlaceholders(translatedText, placeholders);
            }

            // If not found or if current locale is the default, use default string
            if (currentLocale !== DEFAULT_LOCALE) {
                console.log(`TRANSLATION_MISSING: "${defaultLanguageStringOrLocalizedString}" (${currentLocale}) | Source: ${source}`);
            }
            this.translationCache.set(cacheKey, defaultLanguageStringOrLocalizedString);
            return this.replacePlaceholders(defaultLanguageStringOrLocalizedString, placeholders);
        } else {
            const localizedString = defaultLanguageStringOrLocalizedString[currentLocale] || defaultLanguageStringOrLocalizedString[DEFAULT_LOCALE];
            if (!localizedString && !suppressErrors) {
                console.log(`LOCALIZED_STRING_MISSING: ${currentLocale} | Source: ${source}`);
            }
            this.translationCache.set(cacheKey, localizedString || '');
            return this.replacePlaceholders(localizedString || '', placeholders);
        }
    }
}

const translationManager = TranslationManager.getInstance();

export const getLocale = () => translationManager.getLocale();
export const setLocale = (locale: SupportedLocale) => translationManager.setLocale(locale);
export const __ = (defaultLanguageStringOrLocalizedString: string | LocalizedString, options: TranslationOptions = {}) =>
    translationManager.__(defaultLanguageStringOrLocalizedString, options);